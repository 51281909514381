<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <template v-if="detail !== null && modules !== null">
      <v-row>
        <v-col>
          <span
            style="cursor: pointer"
            @click="navBack"
          >
            <v-icon
              style="
              font-size: 16px;
              color: #7A1878;
            "
            >
              fas fa-chevron-left
            </v-icon>
            <span
              class="ml-1"
              style="color: #7A1878"
            >
              Kembali
            </span>
          </span>
        </v-col>
      </v-row>

      <v-row class="mt-n5">
        <v-col style="font-size: 26px">
          Detail Produksi
        </v-col>
      </v-row>

      <v-row class="mt-n8">
        <v-col>
          <!-- Informasi Umum -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col
                class="d-flex"
                style="justify-content: space-between"
              >
                <span class="font-common-black-bold">
                  Informasi Umum
                </span>

                <span>
                  <v-chip color="#FFC709">
                    <span class="font-common-white-bold">
                      {{ detail.status_string }}
                    </span>
                  </v-chip>
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  No Produksi
                </span>

                <span class="font-common-bold">
                  {{ detail.code }}
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="12"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  No Order
                </span>

                <span class="font-common-bold">
                  {{ detail.order_code }}
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="12"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Tanggal Order
                </span>

                <span class="font-common-bold">
                  {{ detail.order_date }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex"
                cols="12"
                sm="12"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Aset
                </span>

                <span class="font-common-bold">
                  {{ detail.ass_code }}
                </span>

                <span class="font-common-bold">
                  {{ detail.ass_name }}
                </span>

                <span class="font-common-bold">
                  {{ detail.ass_address }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common">
                  Periode Tayang
                </span>
              </v-col>

              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common">
                  Durasi
                </span>
              </v-col>

              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common">
                  Awal Tayang
                </span>
              </v-col>

              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common">
                  Akhir Tayang
                </span>
              </v-col>
            </v-row>

            <v-row
              v-for="(v, i) in detail.order_period"
              :key="'order-period-' + i"
              class="mt-n3"
            >
              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common-bold">
                  {{ 'Periode ' + v.seq }}
                </span>
              </v-col>

              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common-bold">
                  {{ v.duration + ' Hari' }}
                </span>
              </v-col>

              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common-bold">
                  {{ v.start_date }}
                </span>
              </v-col>

              <v-col
                cols="3"
                sm="3"
              >
                <span class="font-common-bold">
                  {{ v.end_date }}
                </span>
              </v-col>
            </v-row>
          </v-card>

          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col
                class="d-flex"
                cols="6"
                sm="5"
                style="flex-direction: column"
              >
                <span class="font-common-black-bold">
                  Mitra
                </span>

                <span class="mt-2 font-common-bold">
                  {{ detail.prt_comp_name_legal }}
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="6"
                sm="5"
                style="flex-direction: column"
              >
                <span class="font-common-black-bold">
                  Penyewa
                </span>

                <span class="mt-2 font-common-bold">
                  {{ detail.cust_company_name_legal }}
                </span>
              </v-col>
            </v-row>
          </v-card>

          <!-- Dokumen Produksi -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Dokumen Produksi
              </v-col>
            </v-row>

            <v-row
              v-for="(v, i) in modules.section_document.list_documents"
              :key="'production-doc-' + i"
            >
              <v-col
                class="d-flex"
                cols="3"
                sm="3"
                style="align-items: center"
              >
                <span class="font-common">
                  {{ v.name + ' (' + v.file_size + ')' }}
                  <span
                    class="ml-1"
                    style="cursor: pointer"
                  >
                    <v-icon class="ml-1">
                      fas fa-arrow-alt-circle-down
                    </v-icon>
                  </span>
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="8"
                sm="8"
              >
                <template v-if="v.status === 1">
                  <span class="font-common">
                    Dokumen Telah Terverifikasi
                  </span>
                </template>

                <template v-if="v.status === 2">
                  <span class="font-common">
                    Dokumen Ditolak
                  </span>
                </template>

                <template v-if="produksiReview">
                  <template v-if="v.status === null || v.status === 3">
                    <span>
                      <v-btn
                        small
                        color="#5DB025"
                        style="
                      border: none;
                      outline: none;
                    "
                        @click="verifikasiDokumenProduksi(1, v)"
                      >
                        <span class="text-capitalize">
                          Telah Sesuai
                        </span>
                      </v-btn>

                      <v-btn
                        small
                        color="#EA3E3A"
                        style="
                      border: none;
                      outline: none;
                    "
                        @click="verifikasiDokumenProduksi(2, v)"
                      >
                        <span class="text-capitalize">
                          Belum Sesuai
                        </span>
                      </v-btn>
                    </span>
                  </template>
                </template>

                <template v-else>
                  <span class="font-common">
                    Tidak ada akses
                  </span>
                </template>
              </v-col>
            </v-row>
          </v-card>

          <!-- Desain Iklan -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Desain Iklan
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <template v-if="modules.section_artwork.file_arr.length > 0">
                  <tiny-slider
                    ref="tinySlider"
                    v-bind="tinySliderOptions"
                  >
                    <div
                      v-for="(photo, i) in modules.section_artwork.file_arr"
                      :key="i"
                    >
                      <v-img
                        :src="photo.path"
                        contain
                        width="300px"
                        height="300px"
                      />
                    </div>
                  </tiny-slider>

                  <template v-if="modules.section_artwork.file_arr > 3">
                    <div class="d-flex justify-space-between arrow">
                      <v-icon
                        x-large
                        style="color: #F2F2F2;"
                        @click="prev"
                      >
                        mdi-chevron-left
                      </v-icon>
                      <v-icon
                        x-large
                        style="color: #F2F2F2;"
                        @click="next"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </div>

                    <div class="d-flex justify-center py-3">
                      <span
                        v-for="(n, i) in listImages"
                        :id="'dot-' + i"
                        :key="n"
                        class="dot"
                        @click="navigationClick(i)"
                      />
                    </div>
                  </template>
                </template>

                <template v-else>
                  <span class="font-common-bold">
                    Belum ada foto
                  </span>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider class="my-0" />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-common">
                {{ modules.section_artwork.status_string || '-' }}
              </v-col>
            </v-row>
          </v-card>

          <!-- Percetakan Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Percetakan Media
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex"
                cols="4"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Vendor
                </span>

                <span class="font-common-bold">
                  {{ modules.section_printer.vendor_name || '-' }}
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="4"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  No. Hp
                </span>

                <span class="font-common-bold">
                  {{ modules.section_printer.vendor_phone || '-' }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider class="my-0" />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-common">
                {{ modules.section_printer.status_string || '-' }}
              </v-col>
            </v-row>
          </v-card>

          <!-- Pemasangan Media -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Pemasangan Media
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="d-flex"
                cols="4"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Vendor
                </span>

                <span class="font-common-bold">
                  {{ modules.section_installer.vendor_name || '-' }}
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="4"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  No. Hp
                </span>

                <span class="font-common-bold">
                  {{ modules.section_installer.vendor_phone || '-' }}
                </span>
              </v-col>

              <v-col
                class="d-flex"
                cols="4"
                sm="4"
                style="flex-direction: column"
              >
                <span class="font-common">
                  Tanggal Pemasangan
                </span>

                <span class="font-common-bold">
                  {{ modules.section_installer.installed_time || '-' }}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <template v-if="modules.section_installer.installed_photo.length > 0">
                  <tiny-slider
                    ref="tinySlider2"
                    v-bind="tinySliderOptions"
                  >
                    <div
                      v-for="(photo, i) in modules.section_installer.installed_photo"
                      :key="i"
                    >
                      <!-- {{ photo.photos[0].link }} -->
                      <v-img
                        :src="photo.photos[0].link"
                        contain
                        width="300px"
                        height="300px"
                      />
                    </div>
                  </tiny-slider>

                  <template v-if="modules.section_installer.installed_photo.length > 3">
                    <div class="d-flex justify-space-between arrow">
                      <v-icon
                        x-large
                        style="color: #F2F2F2;"
                        @click="prev2"
                      >
                        mdi-chevron-left
                      </v-icon>
                      <v-icon
                        x-large
                        style="color: #F2F2F2;"
                        @click="next2"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </div>

                    <div class="d-flex justify-center py-3">
                      <span
                        v-for="(n, i) in listImages2"
                        :id="'dot-' + i"
                        :key="n"
                        class="dot"
                        @click="navigationClick2(i)"
                      />
                    </div>
                  </template>
                </template>

                <template v-else>
                  <span class="font-common-bold">
                    Belum ada foto
                  </span>
                </template>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-divider class="my-0" />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="font-common">
                {{ modules.section_installer.status_string || '-' }}
              </v-col>
            </v-row>
          </v-card>

          <!-- Riwayat log -->
          <v-card class="px-4 pt-4 pb-8">
            <v-row>
              <v-col class="font-common-black-bold">
                Riwayat log
              </v-col>
            </v-row>

            <v-row
              v-for="(v, i) in logs"
              :key="'log-' + i"
            >
              <v-col
                class="d-flex"
                cols="12"
                sm="5"
              >
                <div
                  class="d-flex"
                  style="flex-direction: column"
                >
                  <div
                    style="
                    height: 12px;
                    width: 12px;
                    background: #C4C4C4;
                    border-radius: 50%;
                  "
                  />
                  <div
                    class="ml-1 mt-1"
                    style="
                    width: 1px;
                    height: 80px;
                    background: #C4C4C4;
                  "
                  />
                </div>

                <div
                  class="d-flex ml-2"
                  style="flex-direction: column"
                >
                  <span class="font-common">
                    {{ v.entity }}
                  </span>

                  <span class="font-common">
                    {{ v.time }}
                  </span>

                  <span class="font-common-black-bold mt-2">
                    {{ v.desc }}
                  </span>

                  <div
                    class="mt-2"
                    style="border-bottom: 1px solid #CED2D9"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
    },

    data: () => ({
      tab: null,
      tabDialog: null,
      dialog: false,

      // Produksi Detail:
      detail: null,
      modules: null,
      logs: null,

      // Section Location:
      latitude: '',
      longitude: '',
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
      },
      coordinate: {
        lat: -6.995092,
        long: 110.420914,
      },

      // Photos Section:
      tinySliderOptions: {
        mouseDrag: true,
        loop: false,
        items: 3,
        controls: false,
      },

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      mapCoordinate () {
        const retVal = {
          lat: '',
          lng: '',
        }

        if (this.detail !== null) {
          if (this.detail.coordinate) {
            retVal.lat = this.detail.coordinate.lat
            retVal.lng = this.detail.coordinate.long
          } else {
            retVal.lat = this.coordinate.lat
            retVal.lng = this.coordinate.long
          }
        }

        return retVal
      },

      listImages () {
        let imageCount = 0
        if (this.modules.section_artwork.file_arr) {
          imageCount = this.modules.section_artwork.file_arr.length
        }
        if (imageCount > 0) {
          imageCount = Math.ceil(imageCount / 3)
        }

        return imageCount
      },

      listImages2 () {
        let imageCount = 0
        if (this.modules.section_installer.installed_photo) {
          imageCount = this.modules.section_installer.installed_photo.length
        }
        if (imageCount > 0) {
          imageCount = Math.ceil(imageCount / 3)
        }

        return imageCount
      },

      produksiReview () {
        let v = false
        if (this.userMenus.includes('PRODUKSI_REVIEW')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize()
      // this.getPesanan(1, this.itemsPerPage)
      // this.getPesananOffline(1, this.itemsPerPage)
    },

    methods: {
      initialize () {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          prod_hash: this.$route.params.id,
        }

        axios.post('/v2/t/production/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.details
            vm.modules = res.data.data.modules
            vm.logs = res.data.data.logs
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      verifikasiDokumenProduksi (status, data) {
        // console.log(status)
        // console.log(data)
        const requestBody = {
          prod_hash: this.$route.params.id,
          prod_doc_hash: data.hash,
          new_stat: status,
        }

        axios.post('/v2/t/production/review/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      downloadAttachment (data) {
        const type = data.file_type.toUpperCase()
        if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
          const modal = document.getElementById('myModal')
          const modalImg = document.getElementById('img01')
          modal.style.display = 'block'
          if (data.url) modalImg.src = data.url
          if (data.path) modalImg.src = data.path
          if (data.file_path_view_only) modalImg.src = data.file_path_view_only
          const span = document.getElementsByClassName('close')[0]
          span.onclick = function () {
            modal.style.display = 'none'
          }
        } else if (type === 'PDF') {
          window.open(data.url, '_blank')
        } else {
          window.open(data.url_download, '_blank')
        }
      },

      prev () {
        this.$refs.tinySlider.slider.goTo('prev')
      },

      next () {
        this.$refs.tinySlider.slider.goTo('next')
      },

      navigationClick (i) {
        this.$refs.tinySlider.slider.goTo(i)
      },

      prev2 () {
        this.$refs.tinySlider2.slider.goTo('prev')
      },

      next2 () {
        this.$refs.tinySlider2.slider.goTo('next')
      },

      navigationClick2 (i) {
        this.$refs.tinySlider2.slider.goTo(i)
      },

      toDetail (item) {
        console.log(item)
        this.$router.push({ name: 'AsetDetail', params: { id: item.hash } })
      },

      navBack () {
        this.$router.push({ name: 'Produksi' })
      },

      onChangePage (e) {
        this.getPesanan(e, this.itemsPerPage)
      },

      onChangePagePesananOffline (e) {
        this.getPesananOffline(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  padding-top: 10px;
}

.dot-active, .dot:hover {
  background-color: #717171;
}

/* Next & previous buttons */
.arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  color: white;
  width: 96%;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
